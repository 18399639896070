@tailwind base;
@tailwind components;
@tailwind utilities;

/* @import url('http://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&dsiplay=swap'); */

@import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,300;0,400;0,500;0,700;0,800;0,900;0,1000;1,600&family=Outfit:wght@100;200;300;400;500;600;700;800;900&family=Saira:wght@100;200;300;400;500;600;700;800;900&display=swap');
body {
  margin: 0;
  font-family: 'Saira', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: white;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@layer base {
  @font-face {
    font-family: gilroy-bold;
    src: url(./assets/Fonts/gilroy/Gilroy-Bold.ttf);
  }

  @font-face {
    font-family: gilroy-light;
    src: url(./assets/Fonts/gilroy/Gilroy-Light.ttf);
  }

  @font-face {
    font-family: gilroy-medium;
    src: url(./assets/Fonts/gilroy/Gilroy-Medium.ttf);
  }

  @font-face {
    font-family: gilroy-regular;
    src: url(./assets/Fonts/gilroy/Gilroy-Regular.ttf);
  }

  @font-face {
    font-family: gilroy-semibold;
    src: url(./assets/Fonts/gilroy/Gilroy-SemiBold.ttf);
  }
}

@layer utilities {
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }

  .no-scrollbar {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }

  .cardHeader {
    padding: 18px 20px;
    border-bottom: 1px solid #e9ebec;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

/* SidebarDropdown.css */
.sideBarOpen {
   /* Set a sufficiently large value to accommodate the dropdown content */
  animation: sideBarOpenIdentifire 1s ease-in-out; /* Define the transition animation */
}
@keyframes sideBarOpenIdentifire {
  0% {
    max-height: 0px;
    opacity: 0;
  }
  100% {
    opacity: 1;
    max-height: 600px;
  }
}

/* When the sidebar is closed, set max-height to 0 to hide the content */
.sideBarClosed {
  max-height: 0;
  overflow: hidden; /* Hide the overflow content when closed */
  animation: sideBarCloseIdentifire 0.5s ease-in-out;
}
@keyframes sideBarCloseIdentifire {
  0% {
    max-height: 600px;
    
  }
  100% {
    
    max-height: 0px;
  }
}
.customRadio input {
  display : none ;
}

.customRadio input:checked + span:before {
   border : 4px solid #506FE4;
}
.customRadio span {
  display: flex;
  align-items: center;
}

.customRadio span:before {
  content: "";
  width: 16px;
  height: 16px;
  border-radius: 50%;
  margin-right: 8px;
}
.sideBarBackground {
  background-image: linear-gradient(14deg, #005EB4 0%, #38A0DF 100%) ;
}
.darkSideBarBackground {
  background-image: url(assets/Images/Common/denimbg.webp);
}
.LoginPageBg{
  background-image: radial-gradient(circle at top right,#506fe46e,#fff 42%,#0000 0),radial-gradient(circle at bottom left,#506fe46e,#fff 42%,#0000 0);
}
.ImagePrimaryFilter {
  filter: invert(43%) sepia(13%) saturate(4951%) hue-rotate(201deg) brightness(94%) contrast(90%);
}
.boxShadowIdeal {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
}
.datePicker input {
  background: transparent;
  font-weight: 400;
  color: #212529;
}
.dropdownAnimation {
  animation: dropdownAnimate 0.1s linear;
}
.reactDateRange .rdrMonth {
  width: 100%
}
@keyframes dropdownAnimate {
  0% {
    margin-top: 8px;
  }
  100% {
    margin-top: -1px;
  }
}